import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-container" }

import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import { TokenInfoMap } from '@/types/TokenList';

import { InvestMathResponse } from '../../composables/useInvestMath';
import useInvestState from '../../composables/useInvestState';
import InvestPreviewActions from './InvestPreviewActions.vue';
import InvestSummary from './InvestSummary.vue';
import TokenAmounts from '../InvestPreviewModal/components/TokenAmounts.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  math: InvestMathResponse;
  tokenAddresses: string[];
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    math: { type: null, required: true },
    tokenAddresses: { type: Array, required: true }
  } as unknown as undefined,
  emits: ["close", "showStakeModal", "confirmInvestment"] as unknown as undefined,
  setup(__props: {
  pool: Pool;
  math: InvestMathResponse;
  tokenAddresses: string[];
}, { emit }: { emit: ({
  (e: 'close'): void;
  (e: 'showStakeModal'): void;
  (e: 'confirmInvestment'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const investmentConfirmed = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { getToken } = useTokens();
const { toFiat } = useNumbers();
const { fullAmounts, priceImpact, highPriceImpact, rektPriceImpact } = toRefs(
  props.math
);
const { resetAmounts } = useInvestState();

/**
 * COMPUTED
 */
const title = computed((): string =>
  investmentConfirmed.value
    ? t('investment.preview.titles.confirmed')
    : t('investment.preview.titles.default')
);

const amountMap = computed((): AmountMap => {
  const amountMap = {};
  fullAmounts.value.forEach((amount, i) => {
    amountMap[props.tokenAddresses[i]] = amount;
  });
  return amountMap;
});

const tokenMap = computed((): TokenInfoMap => {
  const tokenMap = {};
  Object.keys(amountMap.value).forEach(address => {
    tokenMap[address] = getToken(address);
  });
  return tokenMap;
});

const fiatAmountMap = computed((): AmountMap => {
  const fiatAmountMap = {};
  Object.keys(amountMap.value).forEach(address => {
    fiatAmountMap[address] = toFiat(amountMap.value[address], address);
  });
  return fiatAmountMap;
});

const fiatTotal = computed((): string =>
  Object.values(fiatAmountMap.value).reduce(
    (total, amount) => bnum(total).plus(amount).toString(),
    '0'
  )
);

/**
 * METHODS
 */
function onSuccess(): void {
  emit('confirmInvestment');
}

return (_ctx: any,_cache: any) => {
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BalCard, {
      noPad: "",
      noBorder: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(TokenAmounts, {
            amountMap: _unref(amountMap),
            tokenMap: _unref(tokenMap),
            fiatAmountMap: _unref(fiatAmountMap),
            fiatTotal: _unref(fiatTotal)
          }, null, 8, ["amountMap", "tokenMap", "fiatAmountMap", "fiatTotal"]),
          _createVNode(InvestSummary, {
            pool: __props.pool,
            fiatTotal: _unref(fiatTotal),
            priceImpact: _unref(priceImpact),
            highPriceImpact: _unref(highPriceImpact)
          }, null, 8, ["pool", "fiatTotal", "priceImpact", "highPriceImpact"]),
          (_unref(rektPriceImpact))
            ? (_openBlock(), _createBlock(_component_BalAlert, {
                key: 0,
                type: "error",
                title: _ctx.$t('investment.error.rektPriceImpact.title'),
                description: _ctx.$t('investment.error.rektPriceImpact.description'),
                class: "mt-6 mb-2"
              }, null, 8, ["title", "description"]))
            : _createCommentVNode("", true),
          _createVNode(InvestPreviewActions, {
            pool: __props.pool,
            math: __props.math,
            tokenAddresses: __props.tokenAddresses,
            disabled: _unref(rektPriceImpact),
            onSuccess: onSuccess,
            class: "mt-4"
          }, null, 8, ["pool", "math", "tokenAddresses", "disabled"])
        ])
      ]),
      _: 1
    })
  ]))
}
}

})
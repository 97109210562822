import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 1 }

import {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/abstract-provider';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from 'ethers';
import { computed, reactive, toRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import BalActionSteps from '@/components/_global/BalActionSteps/BalActionSteps.vue';
import ConfirmationIndicator from '@/components/web3/ConfirmationIndicator.vue';
import useStaking from '@/composables/staking/useStaking';
import useEthers from '@/composables/useEthers';
import { usePool } from '@/composables/usePool';
import { dateTimeLabelFor } from '@/composables/useTime';
import useTokenApprovalActions from '@/composables/useTokenApprovalActions';
import useTransactions from '@/composables/useTransactions';
import useVeBal from '@/composables/useVeBAL';
import { POOLS } from '@/constants/pools';
import { boostedJoinBatchSwap } from '@/lib/utils/balancer/swapper';
import PoolExchange from '@/services/pool/exchange/exchange.service';
// Types
import { Pool } from '@/services/pool/types';
// Composables
import useWeb3 from '@/services/web3/useWeb3';
import { TransactionActionInfo } from '@/types/transactions';

import { InvestMathResponse } from '../../../composables/useInvestMath';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  math: InvestMathResponse;
  tokenAddresses: string[];
  disabled: boolean;
};

type InvestmentState = {
  init: boolean;
  confirming: boolean;
  confirmed: boolean;
  confirmedAt: string;
  receipt?: TransactionReceipt;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    math: { type: null, required: true },
    tokenAddresses: { type: Array, required: true },
    disabled: { type: Boolean, required: true }
  } as unknown as undefined,
  emits: ["success", "showStakeModal"] as unknown as undefined,
  setup(__props: {
  pool: Pool;
  math: InvestMathResponse;
  tokenAddresses: string[];
  disabled: boolean;
}, { emit }: { emit: ({
  (e: 'success', value: TransactionReceipt): void;
  (e: 'showStakeModal'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const investmentState = reactive<InvestmentState>({
  init: false,
  confirming: false,
  confirmed: false,
  confirmedAt: '',
});

/**
 * COMPOSABLES
 */
const route = useRoute();
const { t } = useI18n();
const { account, getProvider, blockNumber } = useWeb3();
const { addTransaction } = useTransactions();
const { txListener, getTxConfirmedAt } = useEthers();
const { lockablePoolId } = useVeBal();
const { isPoolEligibleForStaking } = useStaking();

const { poolWeightsLabel } = usePool(toRef(props, 'pool'));
const {
  fullAmounts,
  batchSwapAmountMap,
  bptOut,
  fiatTotalLabel,
  batchSwap,
  shouldFetchBatchSwap,
} = toRefs(props.math);

const { tokenApprovalActions } = useTokenApprovalActions(
  props.tokenAddresses,
  fullAmounts
);

/**
 * SERVICES
 */
const poolExchange = new PoolExchange(toRef(props, 'pool'));

/**
 * COMPUTED
 */
const actions = computed((): TransactionActionInfo[] => [
  ...tokenApprovalActions,
  {
    label: t('invest'),
    loadingLabel: t('investment.preview.loadingLabel.investment'),
    confirmingLabel: t('confirming'),
    action: submit,
    stepTooltip: t('investmentTooltip'),
  },
]);

const transactionInProgress = computed(
  (): boolean =>
    investmentState.init ||
    investmentState.confirming ||
    investmentState.confirmed
);

const isStakablePool = computed((): boolean => {
  return (
    POOLS.Stakable.AllowList.includes(route.params.id as string) &&
    isPoolEligibleForStaking.value
  );
});

/**
 * METHODS
 */

async function handleTransaction(tx): Promise<void> {
  addTransaction({
    id: tx.hash,
    type: 'tx',
    action: 'invest',
    summary: t('transactionSummary.investInPool', [
      fiatTotalLabel.value,
      poolWeightsLabel(props.pool),
    ]),
    details: {
      total: fiatTotalLabel.value,
      pool: props.pool,
    },
  });

  await txListener(tx, {
    onTxConfirmed: async (receipt: TransactionReceipt) => {
      emit('success', receipt);
      investmentState.receipt = receipt;

      const confirmedAt = await getTxConfirmedAt(receipt);
      investmentState.confirmedAt = dateTimeLabelFor(confirmedAt);
      investmentState.confirmed = true;
      investmentState.confirming = false;
    },
    onTxFailed: () => {
      console.error('Invest failed');
      investmentState.confirming = false;
    },
  });
}

async function submit(): Promise<TransactionResponse> {
  try {
    let tx;
    investmentState.init = true;

    if (batchSwap.value) {
      tx = await boostedJoinBatchSwap(
        batchSwap.value.swaps,
        batchSwap.value.assets,
        props.pool.address,
        batchSwapAmountMap.value,
        BigNumber.from(bptOut.value)
      );
    } else {
      tx = await poolExchange.join(
        getProvider(),
        account.value,
        fullAmounts.value,
        props.tokenAddresses,
        formatUnits(bptOut.value, props.pool?.onchain?.decimals || 18)
      );
    }

    investmentState.init = false;
    investmentState.confirming = true;

    console.log('Receipt', tx);

    handleTransaction(tx);
    return tx;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 * WATCHERS
 */
watch(blockNumber, async () => {
  if (shouldFetchBatchSwap.value && !transactionInProgress.value) {
    await props.math.getBatchSwap();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_StarsIcon = _resolveComponent("StarsIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (!_unref(investmentState).confirmed)
        ? (_openBlock(), _createBlock(BalActionSteps, {
            key: 0,
            actions: _unref(actions),
            disabled: __props.disabled
          }, null, 8, ["actions", "disabled"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(ConfirmationIndicator, {
              txReceipt: _unref(investmentState).receipt
            }, null, 8, ["txReceipt"]),
            (_unref(lockablePoolId) === __props.pool.id)
              ? (_openBlock(), _createBlock(_component_BalBtn, {
                  key: 0,
                  tag: "router-link",
                  to: { name: 'get-vebal' },
                  color: "gradient",
                  block: "",
                  class: "mt-2 flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_StarsIcon, { class: "mr-2 h-5 text-orange-300" }),
                    _createTextVNode(_toDisplayString(_ctx.$t('lockToGetVeBAL')), 1)
                  ]),
                  _: 1
                }))
              : (_unref(isStakablePool))
                ? (_openBlock(), _createBlock(_component_BalBtn, {
                    key: 1,
                    color: "gradient",
                    block: "",
                    class: "mt-2 flex",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('showStakeModal')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_StarsIcon, { class: "mr-2 h-5 text-orange-300" }),
                      _createTextVNode(_toDisplayString(_ctx.$t('stakeToGetExtra')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
            _createVNode(_component_BalBtn, {
              tag: "router-link",
              to: { name: 'pool', params: { id: _unref(route).params.id } },
              color: "gray",
              outline: "",
              block: "",
              class: "mt-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('returnToPool')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]))
    ]),
    _: 1
  }))
}
}

})
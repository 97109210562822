
import { ref, defineComponent, computed, toRef, toRefs, reactive } from 'vue';
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import { isStableLike, isStablePhantom, usePool } from '@/composables/usePool';
import useInvestState from '@/components/forms/pool_actions/InvestForm/composables/useInvestState';
import { forChange } from '@/lib/utils';
import TradeSettingsPopover, {
  TradeSettingsContext,
} from '@/components/popovers/TradeSettingsPopover.vue';
import PoolUserStats from '@/components/contextual/pages/pool/PoolUserStats.vue';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import { useRoute, useRouter } from 'vue-router';
import InvestForm from '@/components/forms/pool_actions/InvestForm/InvestForm.vue';
import InvestPreviewModal from '@/components/forms/pool_actions/InvestForm/components/InvestPreviewModal/InvestPreviewModal.vue';
import useWeb3 from '@/services/web3/useWeb3';
import ArrowLeftIcon from '@/components/_global/icons/ArrowLeftIcon.vue';
import CloseIcon from '@/components/_global/icons/CloseIcon.vue';
import QuestionIcon from '../../components/_global/icons/QuestionIcon.vue';
import TickIcon from '../../components/_global/icons/TickIcon.vue';
import StakeView from './Stake.vue';
import useStake from '@/composables/PolarisFinance/useStake';
import { TransactionResponse } from '@ethersproject/providers';
import useTransactions from '@/composables/useTransactions';
import useEthers from '@/composables/useEthers';
import useTokens from '@/composables/useTokens';
import InvestHero from '@/components/heros/InvestHero.vue';
import useBreakpoints from '@/composables/useBreakpoints';

const steps = [
  {
    step: 1,
    headline: 'Connect your wallet to start earning',
    button: 'Connect wallet',
  },
  {
    step: 2,
    headline: 'Choose your token amounts to invest',
    button: 'Create LP',
  },
  {
    step: 3,
    headline: 'Preview your investment',
    button: 'Preview & confirmation',
  },
  {
    step: 4,
    headline: 'Approve staking of your invested LP tokens',
    button: 'Approve staking',
  },
  {
    step: 5,
    headline: 'Stake your liquidity pool tokens',
    button: 'Stake LP',
  },
  {
    step: 6,
    headline: 'Everything is set and done!',
    button: 'Done',
  },
];

export default defineComponent({
  components: {
    PoolUserStats,
    InvestForm,
    InvestPreviewModal,
    QuestionIcon,
    ArrowLeftIcon,
    CloseIcon,
    StakeView,
    TickIcon,
    InvestHero,
  },
  props: {},
  watch: {
    isWalletReady(newValue, oldValue) {
      if (newValue == true) this.activeStep = 2;
    },
    async account() {
      const { isApproved } = useStake();
      this.poolApproved = await isApproved(this.pool?.address!, this.account);
      if (this.activeStep == 4) {
        this.activeStep = 5;
      }
    },
    async pool() {
      const { isApproved } = useStake();
      this.poolApproved = await isApproved(this.pool?.address!, this.account);
      if (this.activeStep == 4) {
        this.activeStep = 5;
      }
    },
  },
  setup(props, { emit }) {
    const { isMobile, isDesktop } = useBreakpoints();
    const { transfersAllowed } = usePoolTransfers();
    const route = useRoute();
    const id = ref<string>(route.params.id as string);

    const poolQuery = usePoolQuery(route.params.id as string);
    const pool = computed(() => poolQuery.data.value);
    const { isStablePhantomPool } = usePool(pool);
    const { tokenAddresses, amounts, sor, sorReady } = useInvestState();

    const {
      account,
      isWalletReady,
      getProvider,
      startConnectWithInjectedProvider,
      isMismatchedNetwork,
    } = useWeb3();
    const { addTransaction } = useTransactions();
    const { balanceFor } = useTokens();

    const investmentTokens = computed((): string[] => {
      if (isStablePhantom(pool.value!.poolType)) {
        return pool.value!.mainTokens || [];
      }
      return pool.value!.tokensList;
    });

    const poolQueryLoading = computed(
      () =>
        poolQuery.isLoading.value ||
        poolQuery.isIdle.value ||
        Boolean(poolQuery.error.value)
    );
    const loadingPool = computed(() => poolQueryLoading.value || !pool.value);

    return {
      id,
      account,
      transfersAllowed,
      sorReady,
      sor,
      pool,
      TradeSettingsContext,
      isStablePhantomPool,
      loadingPool,
      investmentTokens,
      isWalletReady,
      getProvider,
      addTransaction,
      balanceFor,
      steps,
      isDesktop,
      isMobile,
    };
  },
  async mounted() {
    if (this.pool != undefined && this.isStablePhantomPool) {
      // Initialise SOR for batch swap queries
      this.sorReady = await this.sor.fetchPools();
    } else {
      this.sorReady = true;
    }
    const { balance, isApproved } = useStake();
    const approval = await isApproved(this.pool?.address!, this.account);
    this.poolApproved = approval;
    this.stakedBalance = await balance(this.pool?.address!, this.account);
  },
  beforeMount() {
    if (this.isWalletReady && this.activeStep == 1) {
      this.activeStep = 2;
    }
  },
  updated() {},
  data() {
    return {
      activeStep: 1,
      tokenAddresses: [] as string[],
      poolApproved: false,
      stakedBalance: '0',
    };
  },
  methods: {
    setActiveStep(step) {
      if (this.poolApproved == true && step == 4) {
        this.activeStep = step + 1;
      } else if (step <= steps.length) this.activeStep = step;
    },
    clickActiveStep(step) {
      if (step != steps.length) this.setActiveStep(step);
    },
    handleLPPreview() {
      this.setActiveStep(this.activeStep + 1); //THIS WILL BE OK. TESTING BELOW

      // this.handleStakeConfirmed();
      // this.activeStep = 3;
      // if(this.poolApproved){
      //   this.activeStep = this.activeStep+2;
      // }else{
      //   this.approvePool();
      //   this.activeStep = this.activeStep+1;
      // }
    },
    handleInvestConfirm() {
      // const poolApproved = false;      //TESTING
      if (this.poolApproved) {
        this.setActiveStep(this.activeStep + 2);
      } else {
        this.setActiveStep(this.activeStep + 1);
        this.approvePool();
      }
    },
    handleStakeConfirmed() {
      this.setActiveStep(this.activeStep + 1);
      //this.$router.push({ name: 'pool', params: { id: this.pool?.id }});
    },
    progressPerc() {
      if (this.activeStep == steps.length) {
        return 1;
      }
      return (1 / (steps.length - 1)) * (this.activeStep - 1) + 0.05;
    },
    goBack() {
      if (this.isWalletReady && this.activeStep == 2) return;
      if (!this.isWalletReady && this.activeStep == 1) return;

      this.setActiveStep(this.activeStep - 1);
    },
    async approvePool() {
      const { approve } = useStake();
      let poolAddress = '';
      if (this.pool) {
        poolAddress = this.pool.address;
      }
      const tx = await approve(poolAddress, this.getProvider());

      (tx: TransactionResponse): void => {
        this.addTransaction({
          id: tx.hash,
          type: 'tx',
          action: 'approve',
          summary: 'approve for staking',
        });
      };

      const { txListener } = useEthers();
      txListener(tx, {
        onTxConfirmed: () => {
          this.setActiveStep(this.activeStep + 1);
          this.poolApproved = true;
        },
        onTxFailed: () => {},
      });
    },
  },

  emits: [],
});

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-30b5f791")
const _hoisted_1 = { class: "summary-table" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "summary-table-label flex-1" }
const _hoisted_4 = { class: "summary-table-label flex-1 text-right" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "summary-table-number flex-1" }
_popScopeId()

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import { Pool } from '@/services/pool/types';
/**
 * TYPES
 */
type Props = {
  pool: Pool;
  fiatTotal: string;
  priceImpact: number;
  isLoadingPriceImpact?: boolean;
  highPriceImpact?: boolean;
};
/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    fiatTotal: { type: String, required: true },
    priceImpact: { type: Number, required: true },
    isLoadingPriceImpact: { type: Boolean, required: false, default: false },
    highPriceImpact: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  fiatTotal: string;
  priceImpact: number;
  isLoadingPriceImpact?: boolean;
  highPriceImpact?: boolean;
}) {


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { currency } = useUserSettings();

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('total')) + " ", 1),
        _createVNode(_component_BalTooltip, {
          text: _ctx.$t('tooltips.invest.total', [_unref(currency).toUpperCase()]),
          iconSize: "xs",
          class: "ml-1 inline"
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(fNum2)(__props.fiatTotal, _unref(FNumFormats).fiat)), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([
        'flex',
        {
          'bg-red-50 text-red-500 dark:bg-red-500 dark:text-white':
            __props.highPriceImpact,
        },
      ])
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString(_ctx.$t('priceImpact')) + " ", 1),
        _createVNode(_component_BalTooltip, {
          text: _ctx.$t('tooltips.invest.priceImpact'),
          iconSize: "xs",
          iconName: __props.highPriceImpact ? 'alert-triangle' : 'info',
          iconClass: 
              __props.highPriceImpact
                ? 'text-red-500 dark:text-white'
                : 'text-gray-300'
            ,
          width: "50",
          class: "ml-1 inline"
        }, null, 8, ["text", "iconName", "iconClass"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (__props.isLoadingPriceImpact)
          ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
              key: 0,
              class: "h-6 w-10"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_unref(fNum2)(__props.priceImpact, _unref(FNumFormats).percent)), 1)
            ], 64))
      ])
    ], 2)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a63c73da")
const _hoisted_1 = { class: "token-amount-table" }
const _hoisted_2 = { class: "token-amount-table-content items-center" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "ml-3 flex flex-col" }
const _hoisted_5 = { class: "text-lg font-medium" }
const _hoisted_6 = { class: "font-numeric" }
const _hoisted_7 = { class: "text-secondary font-numeric  text-right" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "perc" }
const _hoisted_10 = {
  key: 0,
  class: "token-amount-table-content -mb-2 items-start"
}
const _hoisted_11 = { class: "mr-6 flex flex-wrap" }
const _hoisted_12 = { class: "text-secondary font-numeric whitespace-nowrap  text-right" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = { class: "perc" }
_popScopeId()

import { groupBy, orderBy } from 'lodash';
import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import { TokenInfoMap } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  amountMap: AmountMap;
  fiatAmountMap: AmountMap;
  tokenMap: TokenInfoMap;
  fiatTotal: string;
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    amountMap: { type: Object, required: true },
    fiatAmountMap: { type: Object, required: true },
    tokenMap: { type: null, required: true },
    fiatTotal: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  amountMap: AmountMap;
  fiatAmountMap: AmountMap;
  tokenMap: TokenInfoMap;
  fiatTotal: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const sortedAmounts = computed(() =>
  orderBy(
    Object.entries(props.fiatAmountMap),
    ([, fiatAmount]) => Number(fiatAmount),
    'desc'
  ).map(([address, fiatAmount]) => ({
    amount: props.amountMap[address],
    fiatAmount,
    address,
  }))
);

const groupedAmounts = computed(() =>
  groupBy(sortedAmounts.value, amounts =>
    bnum(amounts.amount).isZero() ? 'zeroAmounts' : 'nonZeroAmounts'
  )
);

const shouldShowCompactViewForZeroAmounts = computed(
  () => (groupedAmounts.value.zeroAmounts?.length || 0) > 3
);

const amountsToShow = computed(() =>
  shouldShowCompactViewForZeroAmounts.value
    ? groupedAmounts.value.nonZeroAmounts
    : sortedAmounts.value
);
/**
 * METHODS
 */
// The investment amount's relative percentage of the total fiat investment value.
// This has nothing to do with the pool weights.
function amountShare(address: string): string {
  return bnum(props.fiatAmountMap[address]).div(props.fiatTotal).toString();
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(amountsToShow), (token) => {
      return (_openBlock(), _createElementBlock("div", {
        key: token.address,
        class: "relative"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BalAsset, {
              address: token.address,
              size: 36
            }, null, 8, ["address"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(fNum2)(token.amount, _unref(FNumFormats).token)), 1),
                _createTextVNode(" " + _toDisplayString(__props.tokenMap[token.address].symbol), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(fNum2)(token.fiatAmount, _unref(FNumFormats).fiat)), 1),
            _createElementVNode("div", _hoisted_9, "(" + _toDisplayString(_unref(fNum2)(amountShare(token.address), _unref(FNumFormats).percent)) + ")", 1)
          ])
        ])
      ]))
    }), 128)),
    (_unref(shouldShowCompactViewForZeroAmounts))
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupedAmounts).zeroAmounts, (token) => {
              return (_openBlock(), _createElementBlock("div", {
                key: token.address,
                class: "token"
              }, [
                _createVNode(_component_BalAsset, {
                  address: token.address,
                  class: "mr-2"
                }, null, 8, ["address"]),
                _createElementVNode("span", null, _toDisplayString(__props.tokenMap[token.address].symbol), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(fNum2)(0, _unref(FNumFormats).fiat)), 1),
            _createElementVNode("div", _hoisted_14, "(" + _toDisplayString(_unref(fNum2)(0, _unref(FNumFormats).percent)) + ")", 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})
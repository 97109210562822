import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, isRef as _isRef, toDisplayString as _toDisplayString, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6ef2bb0c")
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = {
  key: 0,
  class: "preview"
}
const _hoisted_3 = {
  key: 0,
  class: "invest"
}
const _hoisted_4 = { class: "tokens mx-[12px] " }
const _hoisted_5 = { class: "footer" }
const _hoisted_6 = {
  key: 0,
  class: "mt-4 px-6"
}
const _hoisted_7 = { class: " mx-[16px]" }
const _hoisted_8 = ["disabled"]
_popScopeId()

import { computed, nextTick, onBeforeMount, onMounted, ref, toRef, watch } from 'vue';
// Composables
import { useI18n } from 'vue-i18n';

import WrapStEthLink from '@/components/contextual/pages/pool/invest/WrapStEthLink.vue';
import StakePreviewModal from '@/components/contextual/stake/StakePreviewModal.vue';
// Components
import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import { isStableLike, isStablePhantom, usePool } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import { LOW_LIQUIDITY_THRESHOLD } from '@/constants/poolLiquidity';
import { bnum, findByAddress, isSameAddress } from '@/lib/utils';
import { isRequired } from '@/lib/utils/validations';
import StakingProvider from '@/providers/local/staking/staking.provider';
// Types
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

import InvestFormTotals from './components/InvestFormTotals.vue';
import InvestPreviewModal from './components/InvestPreviewModal/InvestPreviewModal.vue';
import InvestPreview from './components/InvestPreview/InvestPreview.vue';
import useInvestMath from './composables/useInvestMath';
import useInvestState from './composables/useInvestState';
import { array } from 'prop-types';

/**
 * TYPES
 */
enum NativeAsset {
  wrapped = 'wrapped',
  unwrapped = 'unwrapped',
}

type Props = {
  pool: Pool;
  step:number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    step: { type: Number, required: true }
  } as unknown as undefined,
  emits: ['preview','confirmInvestment'],
  setup(__props: {
  pool: Pool;
  step:number;
}, { emit: emits }) {

const props = __props



/**
 * STATE
 */
// const showInvestPreview = ref(false);
const showStakeModal = ref(false);
/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { useNativeAsset } = usePoolTransfers();
const {
  tokenAddresses,
  amounts,
  validInputs,
  highPriceImpactAccepted,
  resetAmounts,
  sor,
} = useInvestState();

const investMath = useInvestMath(
  toRef(props, 'pool'),
  tokenAddresses,
  amounts,
  useNativeAsset,
  sor
);

const {
  fiatTotal,
  hasAmounts,
  highPriceImpact,
  maximizeAmounts,
  optimizeAmounts,
  proportionalAmounts,
  batchSwapLoading,
} = investMath;

let maxInvestTotal="0";


const { isWalletReady, startConnectWithInjectedProvider, isMismatchedNetwork } =
  useWeb3();

const { managedPoolWithTradingHalted, isWethPool, isStableLikePool } = usePool(
  toRef(props, 'pool')
);

/**
 * COMPUTED
 */
const hasValidInputs = computed(
  (): boolean =>
    validInputs.value.every(validInput => validInput === true) &&
    hasAcceptedHighPriceImpact.value
);

const getMaxInvestTotal = computed(
  (): string => maxInvestTotal
);

const hasAcceptedHighPriceImpact = computed((): boolean =>
  highPriceImpact.value ? highPriceImpactAccepted.value : true
);

const forceProportionalInputs = computed(
  (): boolean => managedPoolWithTradingHalted.value
);

const poolHasLowLiquidity = computed((): boolean =>
  bnum(props.pool.totalLiquidity).lt(LOW_LIQUIDITY_THRESHOLD)
);

const investmentTokens = computed((): string[] => {
  if (isStablePhantom(props.pool.poolType)) {
    return props.pool.mainTokens || [];
  }
  return props.pool.tokensList;
});

/**
 * METHODS
 */
function handleAmountChange(value: string, index: number): void {
  amounts.value[index] = value;
  // console.log(amounts);
  nextTick(() => {
    if (forceProportionalInputs.value) {
      amounts.value = [...proportionalAmounts.value];
    }
  });
}

function handleAddressChange(newAddress: string): void {
  useNativeAsset.value = isSameAddress(newAddress, nativeAsset.address);
}

function tokenWeight(address: string): number {
  if (isStableLike(props.pool.poolType)) return 0;
  if (!props.pool?.onchain?.tokens) return 0;

  if (isSameAddress(address, nativeAsset.address)) {
    return (
      findByAddress(props.pool.onchain.tokens, wrappedNativeAsset.value.address)
        ?.weight || 1
    );
  }

  return findByAddress(props.pool.onchain.tokens, address)?.weight || 1;
}

function propAmountFor(index: number): string {
  if (isStableLikePool.value) return '0.0';

  return bnum(proportionalAmounts.value[index]).gt(0)
    ? proportionalAmounts.value[index]
    : '0.0';
}
function hint(index: number): string {
  return bnum(propAmountFor(index)).gt(0) ? t('proportionalSuggestion') : '';
}

function tokenOptions(index: number): string[] {
  return isSameAddress(
    props.pool.tokensList[index],
    wrappedNativeAsset.value.address
  )
    ? [wrappedNativeAsset.value.address, nativeAsset.address]
    : [];
}

// If ETH has a higher balance than WETH then use it for the input.
function setNativeAssetByBalance(): void {
  const nativeAssetBalance = balanceFor(nativeAsset.address);
  const wrappedNativeAssetBalance = balanceFor(
    wrappedNativeAsset.value.address
  );

  if (bnum(nativeAssetBalance).gt(wrappedNativeAssetBalance)) {
    setNativeAsset(NativeAsset.unwrapped);
    useNativeAsset.value = true;
  }
}

function setNativeAsset(to: NativeAsset): void {
  const fromAddress =
    to === NativeAsset.wrapped
      ? nativeAsset.address
      : wrappedNativeAsset.value.address;
  const toAddress =
    to === NativeAsset.wrapped
      ? wrappedNativeAsset.value.address
      : nativeAsset.address;

  const indexOfAsset = tokenAddresses.value.indexOf(fromAddress);

  if (indexOfAsset >= 0) {
    tokenAddresses.value[indexOfAsset] = toAddress;
  }
}

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  maximizeAmounts();
  maxInvestTotal = fiatTotal.value;
  resetAmounts();
  
  tokenAddresses.value = [...investmentTokens.value];
  if (isWethPool.value) setNativeAssetByBalance();
});
onMounted(()=>{
  
});

/**
 * METHODS
 */
 function onConfirmInvestment(): void {
  emits('confirmInvestment');
}

/**
 * WATCHERS
 */
watch(useNativeAsset, shouldUseNativeAsset => {
  if (shouldUseNativeAsset) {
    setNativeAsset(NativeAsset.unwrapped);
  } else {
    setNativeAsset(NativeAsset.wrapped);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalCheckbox = _resolveComponent("BalCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (props.step==3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_unref(StakingProvider), {
                poolAddress: __props.pool.address
              }, {
                default: _withCtx(() => [
                  _createVNode(InvestPreview, {
                    class: "preview",
                    pool: __props.pool,
                    math: _unref(investMath),
                    tokenAddresses: _unref(tokenAddresses),
                    onConfirmInvestment: onConfirmInvestment
                  }, null, 8, ["pool", "math", "tokenAddresses"])
                ]),
                _: 1
              }, 8, ["poolAddress"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (props.step<=2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_unref(forceProportionalInputs))
                ? (_openBlock(), _createBlock(_component_BalAlert, {
                    key: 0,
                    type: "warning",
                    title: _ctx.$t('investment.warning.managedPoolTradingHalted.title'),
                    description: 
          _ctx.$t('investment.warning.managedPoolTradingHalted.description')
        ,
                    class: "mb-4"
                  }, null, 8, ["title", "description"]))
                : _createCommentVNode("", true),
              (_unref(poolHasLowLiquidity))
                ? (_openBlock(), _createBlock(_component_BalAlert, {
                    key: 1,
                    type: "warning",
                    title: _ctx.$t('investment.warning.lowLiquidity.title'),
                    description: _ctx.$t('investment.warning.lowLiquidity.description'),
                    class: "mb-4"
                  }, null, 8, ["title", "description"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokenAddresses).length, (n, i) => {
                  return (_openBlock(), _createBlock(TokenInput, {
                    key: i,
                    address: _unref(tokenAddresses)[i],
                    "onUpdate:address": [
                      ($event: any) => (_unref(tokenAddresses)[i] = $event),
                      _cache[0] || (_cache[0] = ($event: any) => (handleAddressChange($event)))
                    ],
                    amount: _unref(amounts)[i],
                    "onUpdate:amount": [($event: any) => (_unref(amounts)[i] = $event), ($event: any) => (handleAmountChange($event, i))],
                    isValid: _unref(validInputs)[i],
                    "onUpdate:isValid": ($event: any) => (_unref(validInputs)[i] = $event),
                    name: _unref(tokenAddresses)[i],
                    weight: tokenWeight(_unref(tokenAddresses)[i]),
                    hintAmount: propAmountFor(i),
                    hint: hint(i),
                    class: "mb-1",
                    fixedToken: "",
                    options: tokenOptions(i)
                  }, null, 8, ["address", "onUpdate:address", "amount", "onUpdate:amount", "isValid", "onUpdate:isValid", "name", "weight", "hintAmount", "hint", "options"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(InvestFormTotals, {
                  math: _unref(investMath),
                  maxFiatValue: _unref(getMaxInvestTotal),
                  onMaximize: _unref(maximizeAmounts),
                  onOptimize: _unref(optimizeAmounts)
                }, null, 8, ["math", "maxFiatValue", "onMaximize", "onOptimize"]),
                (_unref(highPriceImpact))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_BalCheckbox, {
                        modelValue: _unref(highPriceImpactAccepted),
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(highPriceImpactAccepted) ? highPriceImpactAccepted.value = $event : null)),
                        rules: [_unref(isRequired)(_ctx.$t('priceImpactCheckbox'))],
                        name: "highPriceImpactAccepted",
                        size: "sm",
                        label: _ctx.$t('priceImpactAccept', [_ctx.$t('depositing')])
                      }, null, 8, ["modelValue", "rules", "label"])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(WrapStEthLink, {
                  pool: __props.pool,
                  class: "mt-4"
                }, null, 8, ["pool"]),
                _createElementVNode("div", _hoisted_7, [
                  (!_unref(isWalletReady))
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn active w-full mb-[12px]",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(startConnectWithInjectedProvider) && _unref(startConnectWithInjectedProvider)(...args)))
                      }, _toDisplayString(_ctx.$t('connectWallet')), 1))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "btn active w-full mb-[12px]",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ( emits('preview'))),
                        disabled: 
              !_unref(hasAmounts) ||
              !_unref(hasValidInputs) ||
              _unref(isMismatchedNetwork) ||
              _unref(batchSwapLoading)
            
                      }, _toDisplayString(_ctx.$t('preview')), 9, _hoisted_8))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }

import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import { TokenInfoMap } from '@/types/TokenList';

import { InvestMathResponse } from '../../composables/useInvestMath';
import useInvestState from '../../composables/useInvestState';
import InvestActions from './components/InvestActions.vue';
import InvestSummary from './components/InvestSummary.vue';
import TokenAmounts from './components/TokenAmounts.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  math: InvestMathResponse;
  tokenAddresses: string[];
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    math: { type: null, required: true },
    tokenAddresses: { type: Array, required: true }
  } as unknown as undefined,
  emits: ["close", "showStakeModal"] as unknown as undefined,
  setup(__props: {
  pool: Pool;
  math: InvestMathResponse;
  tokenAddresses: string[];
}, { emit }: { emit: ({
  (e: 'close'): void;
  (e: 'showStakeModal'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const investmentConfirmed = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { getToken } = useTokens();
const { toFiat } = useNumbers();
const { fullAmounts, priceImpact, highPriceImpact, rektPriceImpact } = toRefs(
  props.math
);
const { resetAmounts } = useInvestState();

/**
 * COMPUTED
 */
const title = computed((): string =>
  investmentConfirmed.value
    ? t('investment.preview.titles.confirmed')
    : t('investment.preview.titles.default')
);

const amountMap = computed((): AmountMap => {
  const amountMap = {};
  fullAmounts.value.forEach((amount, i) => {
    amountMap[props.tokenAddresses[i]] = amount;
  });
  return amountMap;
});

const tokenMap = computed((): TokenInfoMap => {
  const tokenMap = {};
  Object.keys(amountMap.value).forEach(address => {
    tokenMap[address] = getToken(address);
  });
  return tokenMap;
});

const fiatAmountMap = computed((): AmountMap => {
  const fiatAmountMap = {};
  Object.keys(amountMap.value).forEach(address => {
    fiatAmountMap[address] = toFiat(amountMap.value[address], address);
  });
  return fiatAmountMap;
});

const fiatTotal = computed((): string =>
  Object.values(fiatAmountMap.value).reduce(
    (total, amount) => bnum(total).plus(amount).toString(),
    '0'
  )
);

/**
 * METHODS
 */
function handleClose(): void {
  if (investmentConfirmed.value) {
    resetAmounts();
  }
  emit('close');
}

function handleShowStakeModal() {
  handleClose();
  emit('showStakeModal');
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createBlock(_component_BalModal, {
    show: "",
    fireworks: investmentConfirmed.value,
    onClose: handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (investmentConfirmed.value)
          ? (_openBlock(), _createBlock(_component_BalCircle, {
              key: 0,
              size: "8",
              color: "green",
              class: "mr-2 text-white"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalIcon, { name: "check" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, _toDisplayString(_unref(title)), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(TokenAmounts, {
        amountMap: _unref(amountMap),
        tokenMap: _unref(tokenMap),
        fiatAmountMap: _unref(fiatAmountMap),
        fiatTotal: _unref(fiatTotal)
      }, null, 8, ["amountMap", "tokenMap", "fiatAmountMap", "fiatTotal"]),
      _createVNode(InvestSummary, {
        pool: __props.pool,
        fiatTotal: _unref(fiatTotal),
        priceImpact: _unref(priceImpact),
        highPriceImpact: _unref(highPriceImpact)
      }, null, 8, ["pool", "fiatTotal", "priceImpact", "highPriceImpact"]),
      (_unref(rektPriceImpact))
        ? (_openBlock(), _createBlock(_component_BalAlert, {
            key: 0,
            type: "error",
            title: _ctx.$t('investment.error.rektPriceImpact.title'),
            description: _ctx.$t('investment.error.rektPriceImpact.description'),
            class: "mt-6 mb-2"
          }, null, 8, ["title", "description"]))
        : _createCommentVNode("", true),
      _createVNode(InvestActions, {
        pool: __props.pool,
        math: __props.math,
        tokenAddresses: __props.tokenAddresses,
        disabled: _unref(rektPriceImpact),
        class: "mt-4",
        onSuccess: _cache[0] || (_cache[0] = ($event: any) => (investmentConfirmed.value = true)),
        onShowStakeModal: handleShowStakeModal
      }, null, 8, ["pool", "math", "tokenAddresses", "disabled"])
    ]),
    _: 1
  }, 8, ["fireworks"]))
}
}

})